import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { rgba } from "polished";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device, breakpoints } from "../../utils";



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const SectionStyled = styled(Section)``;

const SliderStyled = styled(Slider)`
.slick-dots {
  position: relative;

  li {
    font-size: 0;
    width: 17px;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.shadow};
    margin-left: 5px;
    margin-right: 5px;
    transition: 0.5s;
    &.slick-active {
      width: 45px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.secondary};
    }
    button {
      width: 100%;
      height: 100%;
      &:before {
        content: none;
      }
    }
  }
}
  position: relative;

  .slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 0;
    height: 25px;
    display: flex;
    width: 25px;
    background:  ${({ theme }) => theme.colors.secondary};
    transition: 0.4s;
    border-radius: 500px;
    transform: translate(-50%, -50%);
    z-index: 999;
    margin-top: -20px;
    &::before {
      position: absolute;
      content: "";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      transition: 0.4s;
    }
  }
  .slick-arrow.slick-prev {
    left: 0;
    &:before {
      content: "\f060";
    }
    @media ${device.md} {
       margin-left: 60px; 
    }
  }

  .slick-arrow.slick-next {
    right: 0;
    left: auto;
    transform: translate(50%, -50%);
    &:before {
      content: "\f061";
    }
    @media ${device.md} {
       margin-right: 60px; 
    }
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    background: ${({ theme }) => theme.colors.light}!important;
    color: ${({ theme }) => theme.colors.secondary}!important;
    &:before {
      color: ${({ theme }) => theme.colors.secondary}!important;
    }
  }
`;

const SliderItem = styled(Box)`
  &:focus {
    outline: none;
  }
`;

const SliderCard = styled(Box)`
  border-radius: 10px;
  background: #fff;
  overflow: hidden;


  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  @media ${device.sm} {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  @media ${device.md} {
    padding-right: 50px;
    padding-left: 50px;
    flex-direction: row;
    align-items: center;
  }
  @media ${device.lg} {

    padding-right: 100px;
    padding-left: 100px;
  }
  @media ${device.xl} {
    padding-right: 120px;
    padding-left: 120px;
  }
`;

const NumberBlock = styled(Box)`
  min-width: 215px;
  height: 213px;
  border-radius: 8px;
  background-color: #eae9f2;
  text-align: center;
  margin: 0 auto;
  @media ${device.md} {
    margin-left: 0;
  }
  span {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 140px;
    font-weight: 900;
    letter-spacing: -4.38px;
  }
`;

const SliderText = styled(Box)`
  display: flex;
  flex-direction: column;

  flex: auto;
  padding-left: 0px;
  padding-right: 0px;
  @media ${device.sm} {
    padding-left: 60px;
    padding-right: 0;
  }
  @media ${device.md} {
    padding-left: 85px;
  }
  @media ${device.lg} {
    padding-left: 120px;
  }
`;

const Testimonial = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
   
    slidesToShow: 1,
    slidesToScroll: 1,
   
    arrows: false,
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/* <!-- testimonial section --> */}
      <SectionStyled py="4"   bg="#e9f0f2" pb={["10px!important"]}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
            
            <Iwrap>


<Itext as="h2">Treatment Steps</Itext>
</Iwrap>
      <SecondText>
    How does it work?

      </SecondText>
           
             
            </Col>
          </Row>
          <Row className="justify-content-center pb-5">
            <Col lg="12">
              <SliderStyled {...slickSettings}>
                <SliderItem>
                  <SliderCard>
                  
                    <SliderText>
                      <Text variant="small" color="secondary">
Step 1. Consultation                     </Text>
                  
                      <Text color="dark">
                      All of our treatments begin with a private consultation that includes a full facial assessment by our expert practitioner. The desired outcome and suitability of the treatment on the patient will be discussed, and then a tailored treatment plan is devised together.
                      </Text>
                    </SliderText>
                  </SliderCard>
                </SliderItem>
                <SliderItem>
                  <SliderCard>
                  
                    <SliderText>
                      <Text variant="small" color="secondary">
                        Step 2. Treatment
                      </Text>
                      
                      <Text color="dark">
                    
                      Numbing cream is applied to the areas to be treatment, then the treatment is administered following this. The procedure itself is simple and takes only a few minutes. The filler is injected into the targeted areas with a fine needle. 
                      </Text>
                    </SliderText>
                  </SliderCard>
                </SliderItem>
                <SliderItem>
                  <SliderCard>
                   
                    <SliderText>
                      <Text variant="small" color="secondary">
                      Step 3. After Care
                      </Text>
                      
                      <Text color="dark">
                      Following every treatment, a comprehensive after care advise will be provided before leaving the clinic.
                      </Text>
                    </SliderText>
                  </SliderCard>
                </SliderItem>
              </SliderStyled>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Testimonial;
