import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/homepage/content.js";
import Herochin from "../sections/chin/chinhero.js";
import Testimonial from "../sections/advanced/advreviews";
import Treatment from "../sections/homepage/Treatments.js";
import Rte from '../sections/landing/popular';
import Saveface from '../sections/landing/saveface';
import Brand from "../sections/homepage/Brands.js";
import Contentchin from "../sections/chin/chincontent.js";
import RecoveryChin from "../sections/chin/chinrecovery.js";
import GalleryChin from "../sections/chin/chingallery.js";
import ChinFaq from "../sections/chin/chinfaq.js";
import RhinoQ from '../sections/rhino/rhinoquestion';
import Head from "../components/head"

import Pro from '../sections/landing/steps.js';
import Clinic from '../sections/rhino/rhinofind.js';  
import Award from "../sections/index/award.js";
import PopupForm from "../components/PopupForm/PopupForm"


const Chin = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
    <Head title="Chin/Jaw Treatment UK" image="/images/dermamina-home.jpg"  description="Experts in Chin/Jaw treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
<PageWrapper headerDark>
<Herochin />
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<Contentchin
  setIsModalOpen={setIsModalOpen}
/>
<GalleryChin />
<Award />
<Testimonial />
<RecoveryChin />
<Pro />
<ChinFaq
  setIsModalOpen={setIsModalOpen}
/>

<Rte />
<Clinic /> 
<Brand />
<Saveface />
</PageWrapper>

    </>
  );
};
export default Chin;
